import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import benefitsImage from '../../../assets/benefits-image.png';

interface BenefitsProps {
  onGoToSignUp: (placement: string) => void;
}

const Benefits: React.FC<BenefitsProps> = ({
  onGoToSignUp,
}) => {
  return (
    <section className="Benefits">
      <Container className="Benefits__section">
        <Row>
          <Col>
            <div className="Benefits__content">
              <h2 className="Benefits__contentTitle">Why Our Tool Stands Out</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="Benefits__feature">
              <div className="Benefits__featureIcon">
                <i className="bi bi-rocket-takeoff-fill" />
              </div>
              <div className="Benefits__featureText">
                <h3 className="Benefits__featureTitle">Data-Driven Accuracy</h3>
                <p className="Benefits__featureText">Powered by millions of transaction data points, our model offers unparalleled accuracy in price predictions</p>
              </div>
            </div>
            <div className="Benefits__feature">
              <div className="Benefits__featureIcon">
                <i className="bi bi-lightning-charge-fill" />
              </div>
              <div className="Benefits__featureText">
                <h3 className="Benefits__featureTitle">Transparent Pricing Insights</h3>
                <p className="Benefits__featureText">Not just a price – understand why your property is valued as it is, with a breakdown of contributing factors</p>
              </div>
            </div>
            <div className="Benefits__feature">
              <div className="Benefits__featureIcon">
                <i className="bi bi-buildings-fill" />
              </div>
              <div className="Benefits__featureText">
                <h3 className="Benefits__featureTitle">Comprehensive Property Information</h3>
                <p className="Benefits__featureText">Get detailed insights into the property, neighborhood, and market trends to make informed decisions</p>
              </div>
            </div>
            <div className="Benefits__feature">
              <div className="Benefits__featureIcon">
                <i className="bi bi-globe-central-south-asia" />
              </div>
              <div className="Benefits__featureText">
                <h3 className="Benefits__featureTitle">Tailored for Dubai&apos;s Unique Market</h3>
                <p className="Benefits__featureText">Specifically designed for the Dubai real estate market, our tool accounts for the city&apos;s unique dynamics.</p>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="Benefits__image mb-3">
              <img alt="Benefits Interface" src={benefitsImage} />
            </div>
            <Button variant="dark" onClick={() => onGoToSignUp('Benefits')}>Get Free Prediction</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Benefits;

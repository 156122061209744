import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FlowHeader } from '../../../components/Flow/FlowHeader/FlowHeader';
import { FlowText } from '../../../components/Flow/FlowText/FlowText';
import { FlowIcon, FlowIconThemes } from '../../../components/Flow/FlowIcon/FlowIcon';
import { Paths } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getPaymentStatusThunk,
  OrderStatuses,
  selectIsProcessingOrderRequesting,
  selectProcessingOrderStatus,
} from '../../../store/orders.reducer';
import { selectAccessToken } from '../../../store/auth.reducer';
import { getURLParam } from '../../../utils/common';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';

import './PaymentStatus.css';
import { Skeleton } from '../../../components/Skeleton/Skeleton';

export const PaymentStatus: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isProcessingOrderRequesting = useAppSelector(selectIsProcessingOrderRequesting);
  const processingOrderStatus = useAppSelector(selectProcessingOrderStatus);
  const accessToken = useAppSelector(selectAccessToken);
  const [polling, setPolling] = useState<NodeJS.Timer>();
  const [id] = useState(getURLParam('id'));

  const getPaymentStatus = useCallback(() => {
    if (!accessToken) {
      return;
    }

    dispatch(getPaymentStatusThunk({
      accessToken,
      id: Number(id),
      onSuccess: () => {},
    }));
  }, []);

  useEffect(() => {
    getPaymentStatus();
  }, [id]);

  useEffect(() => {
    if (processingOrderStatus === OrderStatuses.PENDING) {
      setPolling(setInterval(getPaymentStatus, 1000 * 5));
      return;
    }

    clearInterval(polling);
  }, [processingOrderStatus]);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.PAYMENT_STATUS_VIEWED);
  }, []);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.PAYMENT_STATUS_UPDATED, {
      status: processingOrderStatus,
    });
  }, [processingOrderStatus]);

  if (isProcessingOrderRequesting) {
    return (
      <div className="PaymentStatus">
        <Container className="PaymentStatus__section Flow__section">
          <Row>
            <Col lg={3} />
            <Col lg={6}>
              <div className="PaymentStatus__block">
                <FlowIcon
                  icon={(
                    <Spinner animation="grow" />
                  )}
                  theme={FlowIconThemes.NEUTRAL}
                />
                <FlowHeader
                  text={(
                    <Skeleton width={100} />
                  )}
                  className="mb-3"
                />
                <FlowText
                  text={(
                    <Skeleton width={200} />
                  )}
                />
              </div>
              <Col lg={3} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (processingOrderStatus === OrderStatuses.SUCCESSFUL) {
    return (
      <div className="PaymentStatus">
        <Container className="PaymentStatus__section Flow__section">
          <Row>
            <Col lg={3} />
            <Col lg={6}>
              <div className="PaymentStatus__block">
                <FlowIcon
                  icon={(
                    <i className="bi bi-check-lg" />
                  )}
                  theme={FlowIconThemes.SUCCESS}
                />
                <FlowHeader
                  text="Successful Payment"
                  className="mb-3"
                />
                <FlowText
                  text={(
                    <>
                      Payment for the order was successful!
                      <br />
                      Now you can get a real estate price prediction.
                    </>
                  )}
                />
                <Button variant="dark" onClick={() => navigate(Paths.PROPERTY_PREDICTION_SELECT_PROJECT)}>
                  Get Prediction
                </Button>
              </div>
              <Col lg={3} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (processingOrderStatus === OrderStatuses.CANCELLED) {
    return (
      <div className="PaymentStatus">
        <Container className="PaymentStatus__section Flow__section">
          <Row>
            <Col lg={3} />
            <Col lg={6}>
              <div className="PaymentStatus__block">
                <FlowIcon
                  icon={(
                    <i className="bi bi-exclamation-circle" />
                  )}
                  theme={FlowIconThemes.DANGER}
                />
                <FlowHeader
                  text="Payment cancelled"
                  className="mb-3"
                />
                <FlowText
                  text={(
                    <>
                      Payment was made with an error, money was not debited.
                      <br />
                      Please try again.
                    </>
                  )}
                />
                <Button variant="dark" onClick={() => navigate(Paths.PAYMENT_PACKS)}>
                  Try again
                </Button>
              </div>
              <Col lg={3} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="PaymentStatus">
      <Container className="PaymentStatus__section Flow__section">
        <Row>
          <Col lg={3} />
          <Col lg={6}>
            <div className="PaymentStatus__block">
              <FlowIcon
                icon={(
                  <i className="bi bi-clock" />
                )}
                theme={FlowIconThemes.WARNING}
              />
              <FlowHeader
                text="Waiting for a payment"
                className="mb-3"
              />
              <FlowText
                text={(
                  <>
                    We are waiting for a response from the payment system.
                    <br />
                    Usually it takes a couple of minutes.
                  </>
                )}
              />
            </div>
            <Col lg={3} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

import { makeRequest } from '../index';
import {
  CreateOrderResponse,
  OrderStatusResponse,
  OrdersResponse,
} from './types';
import { ApiEndpoint, ApiEndpointVersion } from '../../constants';

export async function createOrder(accessToken: string, quantity: number): Promise<CreateOrderResponse | null> {
  return makeRequest<CreateOrderResponse>({
    endpoint: ApiEndpoint.PAYMENT_CREATE_ORDER,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      quantity,
    },
  });
}

export async function getOrderStatus(accessToken: string, id: number): Promise<OrderStatusResponse | null> {
  return makeRequest<OrderStatusResponse>({
    endpoint: ApiEndpoint.PAYMENT_GET_STATUS,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      id,
    },
  });
}

export async function getOrders(accessToken: string): Promise<OrdersResponse | null> {
  return makeRequest<OrdersResponse>({
    endpoint: ApiEndpoint.PAYMENT_GET_ALL,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

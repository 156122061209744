import { makeRequest } from '../index';
import {
  LimitsResponse,
} from './types';
import { ApiEndpoint, ApiEndpointVersion } from '../../constants';

export async function getLimits(accessToken: string): Promise<LimitsResponse | null> {
  return makeRequest<LimitsResponse>({
    endpoint: ApiEndpoint.LIMITS_GET,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

const HowItWorks: React.FC = () => {
  return (
    <section className="HowItWorks">
      <Container className="HowItWorks__section">
        <Row>
          <Col>
            <div className="HowItWorks__content">
              <h2 className="HowItWorks__contentTitle">How Does It Work?</h2>
              <p className="HowItWorks__contentText">
                Few simple steps to get price prediction:
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={4} className="mb-4 mb-lg-0">
            <div className="HowItWorks__step">
              <div className="HowItWorks__stepIcon">
                <i className="bi bi-building-fill" />
              </div>
              <h3 className="HowItWorks__stepTitle">Step 1: Select Your Property</h3>
              <p className="HowItWorks__stepText">Choose the residential complex or building of interest in Dubai</p>
            </div>
          </Col>
          <Col lg={4} className="mb-4 mb-lg-0">
            <div className="HowItWorks__step">
              <div className="HowItWorks__stepIcon">
                <i className="bi bi-gear-fill" />
              </div>
              <h3 className="HowItWorks__stepTitle">Step 2: Enter Property Details</h3>
              <p className="HowItWorks__stepText">Input key property parameters: number of rooms, purchase type, area, and more.</p>
            </div>
          </Col>
          <Col lg={4} className="mb-4 mb-lg-0">
            <div className="HowItWorks__step">
              <div className="HowItWorks__stepIcon">
                <i className="bi bi-tags-fill" />
              </div>
              <h3 className="HowItWorks__stepTitle">Step 3: Get Your Prediction and Insights</h3>
              <p className="HowItWorks__stepText">Receive a detailed price prediction along with the factors that influence the price.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowItWorks;

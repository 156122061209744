import React from 'react';
import {
  Container,
  Row,
  Col,
  Carousel,
  Button,
} from 'react-bootstrap';

interface TargetAudiencesProps {
  onGoToSignUp: (placement: string) => void;
}

const TargetAudiences: React.FC<TargetAudiencesProps> = ({
  onGoToSignUp,
}) => {
  return (
    <section className="TargetAudiences">
      <Container className="TargetAudiences__section">
        <Row>
          <Col>
            <div className="TargetAudiences__content">
              <h2 className="TargetAudiences__contentTitle">Who Can Benefit from Our Tool?</h2>
              <p className="TargetAudiences__contentText">Discover how our predictive analytics tool can transform the way you approach Dubai Real Estate</p>
            </div>
          </Col>
        </Row>
        <Carousel>
          <Carousel.Item>
            <Row>
              <Col lg={6}>
                <div className="TargetAudiences__feature">
                  <div className="TargetAudiences__featureIcon">
                    <i className="bi bi-star-fill" />
                  </div>
                  <div className="TargetAudiences__featureText">
                    <h3 className="TargetAudiences__featureTitle">For Buyers</h3>
                    <p className="TargetAudiences__featureText">
                      <span>Make Informed Decisions: </span>
                      Before committing to a purchase, understand the true market value of the property. Our tool gives you a precise price prediction based on a vast database of real estate transactions in Dubai, ensuring you don’t overpay.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Identify Bargains: </span>
                      Quickly spot properties priced below their predicted value, giving you an edge in negotiations. Our model highlights discrepancies between the asking price and the predicted market value, helping you secure a great deal.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Personalized Insights: </span>
                      Get insights tailored to your specific property of interest, including nearby amenities, market trends, and future value potential.
                    </p>
                    <Button variant="dark" onClick={() => onGoToSignUp('Target Audiences')}>Start Predicting Your Future Home’s Value</Button>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="TargetAudiences__feature">
                  <div className="TargetAudiences__featureIcon">
                    <i className="bi bi-star-fill" />
                  </div>
                  <div className="TargetAudiences__featureText">
                    <h3 className="TargetAudiences__featureTitle">For Sellers</h3>
                    <p className="TargetAudiences__featureText">
                      <span>Price Competitively: </span>
                      Set a price that’s both attractive to buyers and reflective of your property’s true value. Our tool helps you avoid overpricing, which can deter buyers, or underpricing, which can cost you profit.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Market with Confidence: </span>
                      Leverage data-driven insights to justify your asking price to potential buyers. Provide a detailed breakdown of how your property’s features contribute to its value, building trust and transparency in negotiations.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Optimize Time on Market: </span>
                      Sell faster by aligning your price with market realities, ensuring your property doesn’t linger on the market.
                    </p>
                    <Button variant="dark" onClick={() => onGoToSignUp('Target Audiences')}>Get Your Property Valuation Now</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg={6}>
                <div className="TargetAudiences__feature">
                  <div className="TargetAudiences__featureIcon">
                    <i className="bi bi-star-fill" />
                  </div>
                  <div className="TargetAudiences__featureText">
                    <h3 className="TargetAudiences__featureTitle">For Real Estate Agents</h3>
                    <p className="TargetAudiences__featureText">
                      <span>Build Trust with Clients: </span>
                      Use our tool to provide clients with accurate, transparent price predictions backed by data. This helps you build credibility and close deals faster.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Simplify Explanations: </span>
                      Easily explain why a property is priced a certain way. Whether dealing with a buyer or seller, our tool gives you clear, easy-to-understand insights that you can share with your clients.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Stay Ahead of the Competition: </span>
                      Differentiate yourself in the competitive Dubai market by offering advanced predictive analytics that other agents may not have access to.
                    </p>
                    <Button variant="dark" onClick={() => onGoToSignUp('Target Audiences')}>Enhance Your Real Estate Practice</Button>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="TargetAudiences__feature">
                  <div className="TargetAudiences__featureIcon">
                    <i className="bi bi-star-fill" />
                  </div>
                  <div className="TargetAudiences__featureText">
                    <h3 className="TargetAudiences__featureTitle">For Investors</h3>
                    <p className="TargetAudiences__featureText">
                      <span>Maximize ROI: </span>
                      Identify undervalued properties with strong appreciation potential. Our tool analyzes trends and provides insights into which properties are likely to offer the best returns over time.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Portfolio Optimization: </span>
                      Regularly reassess your portfolio’s value and make data-driven decisions on whether to hold, sell, or acquire additional properties.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Risk Mitigation: </span>
                      Understand the factors influencing property prices, enabling you to make informed investment decisions that align with your risk tolerance.
                    </p>
                    <Button variant="dark" onClick={() => onGoToSignUp('Target Audiences')}>Optimize Your Investment Strategy</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg={6}>
                <div className="TargetAudiences__feature">
                  <div className="TargetAudiences__featureIcon">
                    <i className="bi bi-star-fill" />
                  </div>
                  <div className="TargetAudiences__featureText">
                    <h3 className="TargetAudiences__featureTitle">For Property Developers</h3>
                    <p className="TargetAudiences__featureText">
                      <span>Market-Driven Pricing Strategies: </span>
                      Before launching a new development, use our tool to predict the optimal pricing strategy based on current market conditions and trends.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Understand Market Demand: </span>
                      Analyze which property features are most valued by buyers in different areas of Dubai, helping you tailor your developments to market needs.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Forecast Future Trends: </span>
                      Leverage our model’s insights to anticipate future price movements and adjust your projects accordingly.
                    </p>
                    <Button variant="dark" onClick={() => onGoToSignUp('Target Audiences')}>Strategize Your Next Development</Button>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="TargetAudiences__feature">
                  <div className="TargetAudiences__featureIcon">
                    <i className="bi bi-star-fill" />
                  </div>
                  <div className="TargetAudiences__featureText">
                    <h3 className="TargetAudiences__featureTitle">For Appraisers</h3>
                    <p className="TargetAudiences__featureText">
                      <span>Data-Backed Appraisals: </span>
                      Enhance your property appraisals with predictions grounded in extensive transaction data, ensuring accuracy and reliability.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Consistency Across Appraisals: </span>
                      Use our tool to maintain consistency in property valuations, ensuring that all appraisals adhere to market trends and data-driven insights.
                    </p>
                    <p className="TargetAudiences__featureText">
                      <span>Improve Client Satisfaction: </span>
                      Provide clients with transparent, data-supported appraisals that leave no room for doubt or dispute.
                    </p>
                    <Button variant="dark" onClick={() => onGoToSignUp('Target Audiences')}>Enhance Your Appraisal Services</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
};

export default TargetAudiences;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { getUserInfo } from '../api/user';

export interface UserState {
  isRequesting: boolean;
  info: {
    id: string | null;
    email: string | null;
  };
}

export const userInitialState: UserState = {
  isRequesting: false,
  info: {
    id: null,
    email: null,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setIsUserInfoRequesting(state, action: PayloadAction<boolean>) {
      state.isRequesting = action.payload;
    },
    setUserId(state, action: PayloadAction<string | null>) {
      state.info.id = action.payload;
    },
    setUserEmail(state, action: PayloadAction<string | null>) {
      state.info.email = action.payload;
    },
  },
  extraReducers: {},
});

const { reducer } = userSlice;
export { reducer as userReducer };

export const selectIsUserInfoRequesting = (state: AppState) => state.user.isRequesting;
export const selectUserId = (state: AppState) => state.user.info.id;
export const selectUserEmail = (state: AppState) => state.user.info.email;

export const {
  setIsUserInfoRequesting,
  setUserId,
  setUserEmail,
} = userSlice.actions;

export const getUserInfoThunk = createAsyncThunk(
  'user/getInfo',
  async ({
    accessToken,
  } : {
    accessToken: string,
  }, { dispatch }) => {
    dispatch(setIsUserInfoRequesting(true));

    const userInfoResponse = await getUserInfo(accessToken);

    if (!userInfoResponse) {
      return;
    }

    dispatch(setIsUserInfoRequesting(false));
    dispatch(setUserId(userInfoResponse.id));
    dispatch(setUserEmail(userInfoResponse.email));
  },
);

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { getLimits } from '../api/limits';
import { PredictionTypes } from '../constants';

export interface LimitsState {
  isRequesting: boolean;
  limits: {
    availableFreePredictions: number;
    availablePaidPredictions: number;
  };
}

export const limitsInitialState: LimitsState = {
  isRequesting: false,
  limits: {
    availableFreePredictions: 0,
    availablePaidPredictions: 0,
  },
};

const limitsSlice = createSlice({
  name: 'limits',
  initialState: limitsInitialState,
  reducers: {
    setIsLimitsRequesting(state, action: PayloadAction<boolean>) {
      state.isRequesting = action.payload;
    },
    setAvailableFreePredictions(state, action: PayloadAction<number>) {
      state.limits.availableFreePredictions = action.payload;
    },
    setAvailablePaidPredictions(state, action: PayloadAction<number>) {
      state.limits.availablePaidPredictions = action.payload;
    },
  },
  extraReducers: {},
});

const { reducer } = limitsSlice;
export { reducer as limitsReducer };

export const selectIsLimitsRequesting = (state: AppState) => state.limits.isRequesting;
export const selectAvailableFreePredictions = (state: AppState) => state.limits.limits.availableFreePredictions;
export const selectAvailablePaidPredictions = (state: AppState) => state.limits.limits.availablePaidPredictions;

export const {
  setIsLimitsRequesting,
  setAvailableFreePredictions,
  setAvailablePaidPredictions,
} = limitsSlice.actions;

export const getLimitsThunk = createAsyncThunk(
  'limits/getInfo',
  async ({
    accessToken,
  } : {
    accessToken: string,
  }, { dispatch }) => {
    dispatch(setIsLimitsRequesting(true));

    const limitsInfoResponse = await getLimits(accessToken);

    if (!limitsInfoResponse) {
      return;
    }

    dispatch(setIsLimitsRequesting(false));
    dispatch(setAvailableFreePredictions(limitsInfoResponse.limits[PredictionTypes.FREE]));
    dispatch(setAvailablePaidPredictions(limitsInfoResponse.limits[PredictionTypes.PAID]));
  },
);

import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import { useAppSelector } from '../../../store';
import { selectProcessingOrderInfo } from '../../../store/orders.reducer';
import { config } from '../../../config';
import { Paths } from '../../../constants';

import './PaymentForm.css';

export const PaymentForm: FC = () => {
  const navigate = useNavigate();
  const [isFormLoaded, setIsFormLoaded] = useState<boolean>(false);

  const processingOrderInfo = useAppSelector(selectProcessingOrderInfo);

  const loadFormHandler = useCallback(() => {
    // @ts-ignore
    const checkout = new window.YooMoneyCheckoutWidget( // eslint-disable-line
      {
        confirmation_token: processingOrderInfo.confirmationToken,
        return_url: `${config.PAYMENT_RETURN_URL}?id=${processingOrderInfo.id}`,
        error_callback: (error: any) => {
          console.log(error);
        },
      },
    );

    checkout.render('payment-form');
    setIsFormLoaded(true);
  }, [processingOrderInfo]);

  useEffect(() => {
    if (!processingOrderInfo.confirmationToken.length) {
      navigate(Paths.PAYMENT_PACKS);
      return;
    }

    if (isFormLoaded) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
    script.onload = loadFormHandler;
    document.body.appendChild(script);
  }, [processingOrderInfo]);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.PAYMENT_FORM_VIEWED);
  }, []);

  return (
    <section className="PaymentForm">
      <Container>
        <Row>
          <Col>
            <div id="payment-form" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

import { makeRequest } from '../index';
import {
  PropertyInfoRequest,
  PropertyInfoResponse,
} from './types';
import { ApiEndpoint, ApiEndpointVersion } from '../../constants';

export async function getPropertyInfo(data: PropertyInfoRequest, accessToken: string): Promise<PropertyInfoResponse | null> {
  return makeRequest<PropertyInfoResponse>({
    endpoint: ApiEndpoint.PROPERTY_GET_INFO,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

import * as amplitude from '@amplitude/analytics-browser';
import { config } from '../config';

export enum AmplitudeEvents {
  SESSION_STARTED = 'session started',
  LANDING_VIEWED = 'landing viewed',
  LANDING_CTA_BUTTON_CLICKED = 'landing cta-button clicked',
  AUTH_VIEWED = 'auth viewed',
  AUTH_SIGN_UP_COMPLETED = 'auth sign-up completed',
  AUTH_SIGN_IN_COMPLETED = 'auth sign-in completed',
  SELECT_PROJECT_VIEWED = 'select-project viewed',
  SELECT_PROJECT_SELECTED = 'select-project selected',
  ADJUST_PARAMETERS_VIEWED = 'adjust-parameters viewed',
  ADJUST_PARAMETERS_SUBMITED = 'adjust-parameters submited',
  PREDICTION_EXECUTED = 'prediction executed',
  PREDICTION_VIEWED = 'prediction viewed',
  ACCOUNT_VIEWED = 'account viewed',
  TERMS_VIEWED = 'terms viewed',
  PAYMENT_PACKS_VIEWED = 'payment packs viewed',
  PAYMENT_ORDER_CREATED = 'payment order created',
  PAYMENT_FORM_VIEWED = 'payment form viewed',
  PAYMENT_STATUS_VIEWED = 'payment status viewed',
  PAYMENT_STATUS_UPDATED = 'payment status updated',
}

export const initAmplitude = () => {
  amplitude.init(config.AMPLITUDE.API_KEY, {
    minIdLength: 1,
    autocapture: {
      elementInteractions: true,
    },
  });
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string | null | undefined) => {
  if (!userId) {
    return;
  }

  amplitude.setUserId(String(userId));
};

export const setAmplitudeUserProperties = (properties: any) => {
  // amplitude.setUserProperties(properties);
};

export const sendAmplitudeEvent = (
  eventType: string,
  eventProperties?: any,
) => {
  amplitude.track(eventType, eventProperties);
};

import React, { useState, useMemo, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Alert,
} from 'react-bootstrap';

const PREDICTION_PRICE = 1.5;
const MARKETING_SALE = 0.01;
const USD_TO_RUB = 100;

interface PricingProps {
  isFreeCredited?: boolean;
  onGetFreePack: () => void;
  onGetPaidPack: (predictions: number, price: number) => void;
}

const Pricing: React.FC<PricingProps> = ({
  isFreeCredited = false,
  onGetFreePack,
  onGetPaidPack,
}) => {
  const [numberOfPredictions, setNumberOfPredictions] = useState<number>(5);
  const customPrice = useMemo(() => {
    if (numberOfPredictions < 1) {
      return 0;
    }

    if (numberOfPredictions === 10) {
      return 9.99;
    }

    return Number((numberOfPredictions * PREDICTION_PRICE - MARKETING_SALE).toFixed(2));
  }, [numberOfPredictions]);

  const customPriceInRub = useMemo(() => {
    return Number((customPrice * USD_TO_RUB).toFixed(2));
  }, [customPrice]);

  const setNumberOfPredictionsHandler = useCallback((quantity: number) => {
    if (numberOfPredictions === 0) {
      setNumberOfPredictions(NaN);
    }

    setNumberOfPredictions(quantity);
  }, []);

  return (
    <section className="Pricing" id="Pricing">
      <Container className="Pricing__section">
        <Row>
          <Col>
            <div className="Pricing__content">
              <h2 className="Pricing__contentTitle">Pricing</h2>
              <p className="Pricing__contentText">
                Flexible Pricing to Suit Your Needs. Just select the number of predictions you are interested in.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-2 mb-lg-5">
          <Col lg={4} className="mb-4 mb-lg-0">
            <div className={`Pricing__plan ${isFreeCredited && 'Pricing__plan--credited'}`}>
              <h6 className="Pricing__planName">Pack of 5 Predictions</h6>
              <h3 className="Pricing__planPrice">Free</h3>
              <div className="Pricing__planFeature">Get 5 Free Predictions</div>
              <div className="Pricing__planFeature Pricing__planFeature--unavailable">Detailed price explanations</div>
              <div className="Pricing__planFeature Pricing__planFeature--unavailable">Full property information</div>
              <div className="Pricing__planFeature Pricing__planFeature--unavailable">Priority customer support</div>
              <Button className="Pricing__planButton" variant="dark" onClick={() => onGetFreePack()}>Start for Free</Button>
              <div className="Pricing__planText">After sign-up, you will be credited with 5 predictions</div>
            </div>
          </Col>
          <Col lg={4} className="mb-4 mb-lg-0">
            <div className="Pricing__plan Pricing__plan--accent">
              <h6 className="Pricing__planName">Pack of 10 Predictions</h6>
              <h3 className="Pricing__planPrice">
                <span>9.99$</span>
                <span className="Pricing__planPriceSubprice">or 999 RUB</span>
              </h3>
              <div className="Pricing__planFeature">Get 10 Predictions</div>
              <div className="Pricing__planFeature">Detailed price explanations</div>
              <div className="Pricing__planFeature">Full property information</div>
              <div className="Pricing__planFeature">Priority customer support</div>
              <Button className="Pricing__planButton" variant="primary" onClick={() => onGetPaidPack(10, 9.99)}>Get Pack</Button>
              <div className="Pricing__planBadge">Most Popular</div>
              <div className="Pricing__planText">After payment, you will be credited with 10 predictions</div>
            </div>
          </Col>
          <Col lg={4} className="mb-4 mb-lg-0">
            <div className="Pricing__plan">
              <h6 className="Pricing__planName">Custom Pack</h6>
              <h3 className="Pricing__planPrice">
                <span>{`${customPrice}$`}</span>
                <span className="Pricing__planPriceSubprice">{`or ${customPriceInRub} RUB`}</span>
              </h3>
              <div className="Pricing__planFeature">Any number of predictions</div>
              <div className="Pricing__planFeature">Detailed price explanations</div>
              <div className="Pricing__planFeature">Full property information</div>
              <div className="Pricing__planFeature">Priority customer support</div>
              <InputGroup className="Pricing__planButton">
                <Form.Control
                  placeholder="Number of predictions"
                  aria-label="Number of predictions"
                  type="number"
                  value={Number(numberOfPredictions).toString()}
                  min={0}
                  step={1}
                  onChange={(e) => setNumberOfPredictionsHandler(Number(e.target.value))}
                />
                <Button variant="dark" onClick={() => onGetPaidPack(numberOfPredictions, customPrice)}>
                  Get Pack
                </Button>
              </InputGroup>
              <div className="Pricing__planText">
                { `After payment, you will be credited with ${numberOfPredictions} predictions` }
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="light" className="Pricing__banner">
              <div className="Pricing__bannerIcon">
                <i className="bi bi-credit-card-fill pe-2" />
              </div>
              <div className="Pricing__bannerText">
                <b className="mb-3">The payment did not go through, what should I do?</b>
                <p className="mb-0">At the moment we accept payments only in rubles. Payment is available through cards issued in Russia and russian payment systems.</p>
                <p>
                  If you want to pay for your purchase with a card from another country, please write to us by email:&nbsp;
                  <Alert.Link href="mailto:quvedevelopment@ya.ru">quvedevelopment@ya.ru</Alert.Link>
                </p>
              </div>
            </Alert>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';

export interface PropertyAdjustmentsState {
  id: number | null;
  transactionGroup: string,
  registrationType: string,
  rooms: string,
  hasParking: boolean,
  procedureArea: number | null,
}

export const propertyAdjustmentsInitialState: PropertyAdjustmentsState = {
  id: null,
  transactionGroup: '',
  registrationType: '',
  rooms: '',
  hasParking: true,
  procedureArea: null,
};

const propertyAdjustmentsSlice = createSlice({
  name: 'propertyAdjustments',
  initialState: propertyAdjustmentsInitialState,
  reducers: {
    setIsPropertyAdjustmentsId(state, action: PayloadAction<number>) {
      state.id = action.payload;
    },
    setPropertyAdjustments(state, action: PayloadAction<PropertyAdjustmentsState>) {
      state = action.payload;
    },
  },
  extraReducers: {},
});

const { reducer } = propertyAdjustmentsSlice;
export { reducer as propertyAdjustmentsReducer };

export const selectPropertyAdjustmentsId = (state: AppState) => state.propertyAdjustments.id;
export const selectPropertyAdjustments = (state: AppState) => state.propertyAdjustments;

export const {
  setIsPropertyAdjustmentsId,
  setPropertyAdjustments,
} = propertyAdjustmentsSlice.actions;

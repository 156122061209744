import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';

import { useAppDispatch, useAppSelector } from '../../../store';
import {
  Paths,
  PredictionTypes,
  PropertyRegistrationTypes,
  PropertyRoomsTypes,
  PropertyTransactionGroups,
} from '../../../constants';
import {
  selectPropertyBasePrice,
  selectPropertyPredictedPrice,
  selectPropertyPredictionFeaturesImpact,
  selectPropertyPredictionType,
} from '../../../store/property.prediction.reducer';
import {
  getPropertyInfoThunk,
  selectIsPropertyInfoRequesting,
  selectPropertyInfo,
  selectPropertyPredictionParameters,
} from '../../../store/property.info.reducer';
import { selectPropertyAdjustmentsId } from '../../../store/property.adjustments.reducer';
import { selectAccessToken } from '../../../store/auth.reducer';
import { getEnumKeyByValue, getFormattedNumber } from '../../../utils/common';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import { ContentPlug } from '../../../components/ContentPlug/ContentPlug';
import { Skeleton } from '../../../components/Skeleton/Skeleton';

import 'photoswipe/dist/photoswipe.css';
import './Prediction.css';

export const Prediction: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accessToken = useAppSelector(selectAccessToken);
  const id = useAppSelector(selectPropertyAdjustmentsId);
  const isPropertyInfoRequesting = useAppSelector(selectIsPropertyInfoRequesting);
  const propertyInfo = useAppSelector(selectPropertyInfo);
  const propertyPredictedPricePerMeter = useAppSelector(selectPropertyPredictedPrice);
  const propertyPredictionParameters = useAppSelector(selectPropertyPredictionParameters);
  const propertyPredictionType = useAppSelector(selectPropertyPredictionType);
  const propertyBasePrice = useAppSelector(selectPropertyBasePrice);
  const propertyPredictionFeaturesImpact = useAppSelector(selectPropertyPredictionFeaturesImpact);

  // Временно оставили полный предикшен для PredictionTypes.FREE
  const isContentAvailable = useMemo(() => [PredictionTypes.PAID, PredictionTypes.FREE].includes(propertyPredictionType), [propertyPredictionType]);

  const propertyPredictedPriceFull = useMemo(() => {
    if (propertyPredictedPricePerMeter && propertyPredictionParameters.procedureArea) {
      return propertyPredictedPricePerMeter * propertyPredictionParameters.procedureArea;
    }

    return null;
  }, [propertyPredictedPricePerMeter, propertyPredictionParameters.procedureArea]);

  useEffect(() => {
    if (!id) {
      navigate(Paths.PROPERTY_PREDICTION_SELECT_PROJECT);
    }
  }, [id]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    if (!id) {
      return;
    }

    dispatch(getPropertyInfoThunk({
      id,
      accessToken,
    }));
  }, [id]);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.PREDICTION_VIEWED);
  }, []);

  const getFeaturesImpactColorClass = useCallback((value: number | null) => {
    if (value === null) {
      return '';
    }

    if (value > 0) {
      return 'Prediction__contentBlockParametersItemValue--positive';
    }

    if (value < 0) {
      return 'Prediction__contentBlockParametersItemValue--negative';
    }

    return '';
  }, []);

  return (
    <div className="Prediction">
      <Container className="Prediction__section">
        <Row className="mb-3">
          <Col>
            <NavLink to={Paths.PROPERTY_PREDICTION_SELECT_PROJECT} className="Prediction__backButton">
              <i className="bi bi-arrow-left pe-2" />
              <span>Try again</span>
            </NavLink>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={7} className="mb-3 mb-lg-0">
            <h1 className="Prediction__title mb-2">
              {
                isPropertyInfoRequesting
                  ? <Skeleton width={350} />
                  : (
                    <>
                      <span className="Prediction__titleName">{ propertyInfo.projectName }</span>
                      <span className="Prediction__titleRooms">{ getEnumKeyByValue(PropertyRoomsTypes, propertyPredictionParameters.rooms) }</span>
                    </>
                  )
              }
            </h1>
            <div className="Prediction__parameters">
              {
                isPropertyInfoRequesting
                  ? <Skeleton width={200} />
                  : (
                    <div className="Prediction__parametersItem">
                      <div className="Prediction__parametersItemValue">UAE</div>
                      {
                        propertyInfo.masterProject && (
                          <>
                            <div className="Prediction__parametersItemValue">&nbsp;•&nbsp;</div>
                            <div className="Prediction__parametersItemKey">
                              { propertyInfo.masterProject }
                            </div>
                          </>
                        )
                      }
                      {
                        propertyInfo.buildingName && (
                          <>
                            <div className="Prediction__parametersItemValue">&nbsp;•&nbsp;</div>
                            <div className="Prediction__parametersItemKey">
                              { propertyInfo.buildingName }
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
              }
            </div>
          </Col>
          <Col lg={5}>
            <h1 className="Prediction__title text-start text-lg-end mb-2">
              <div className="Prediction__titlePrice">
                <span className="Prediction__titlePriceValue">
                  {`~${propertyPredictedPriceFull ? getFormattedNumber(propertyPredictedPriceFull) : null} `}
                </span>
                <span className="Prediction__titlePriceCurrency">AED</span>
                <span className="Prediction__titlePriceIcon" title="Predicted by AI">
                  <i className="bi bi-stars" />
                </span>
              </div>
            </h1>
            <div className="Prediction__pricePerMeter text-start text-lg-end">
              <span className="Prediction__pricePerMeterValue">
                {`~${propertyPredictedPricePerMeter ? getFormattedNumber(propertyPredictedPricePerMeter) : null} `}
              </span>
              <span className="Prediction__pricePerMeterCurrency">
                AED / m²
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="Prediction__photos">
              <Gallery>
                {
                  propertyInfo.photos.map((url) => (
                    <Item
                      key={url}
                      original={url}
                      width={undefined}
                      height={undefined}
                    >
                      {({ ref, open }) => (
                        <div ref={ref} onClick={open} className="Prediction__photosItem" style={{ backgroundImage: `url(${url})` }} />
                      )}
                    </Item>
                  ))
                }
              </Gallery>
              {
                (propertyInfo.photos.length < 1) && (
                  <div className="Prediction__photosThumb">
                    <i className="bi bi-buildings" />
                  </div>
                )
              }
              {
                (propertyInfo.photos.length < 2) && (
                  <div className="Prediction__photosThumb">
                    <i className="bi bi-buildings" />
                  </div>
                )
              }
              {
                (propertyInfo.photos.length < 3) && (
                  <div className="Prediction__photosThumb">
                    <i className="bi bi-buildings" />
                  </div>
                )
              }
            </div>
            {
              (propertyInfo.photos.length > 0) && (
                <div className="Prediction__photosCopyright">
                  &copy; Photos provided by Propsearch.ae
                </div>
              )
            }
          </Col>
        </Row>
        <Row className="Prediction__content">
          <Col lg={6} className="pe-lg-5">
            {
              isPropertyInfoRequesting ? (
                <div className="Prediction__contentBlock">
                  <h3 className="Prediction__contentBlockTitle">
                    <Skeleton width={150} />
                  </h3>
                  <p>
                    <Skeleton width={300} />
                  </p>
                </div>
              ) : (
                propertyInfo.overview && (
                  <div className="Prediction__contentBlock">
                    <h3 className="Prediction__contentBlockTitle">
                      About Property
                    </h3>
                    <p>
                      { propertyInfo.overview }
                    </p>
                  </div>
                )
              )
            }
            {
              isPropertyInfoRequesting ? (
                <div className="Prediction__contentBlock">
                  <h3 className="Prediction__contentBlockTitle">
                    <Skeleton width={150} />
                  </h3>
                  <div className="Prediction__contentBlockParameters">
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        <Skeleton width={200} />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        <Skeleton width={100} />
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        <Skeleton width={200} />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        <Skeleton width={100} />
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        <Skeleton width={200} />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        <Skeleton width={100} />
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        <Skeleton width={200} />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        <Skeleton width={100} />
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        <Skeleton width={200} />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        <Skeleton width={100} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="Prediction__contentBlock">
                  <h3 className="Prediction__contentBlockTitle">
                    Building Parameters
                  </h3>
                  <div className="Prediction__contentBlockParameters">
                    {
                      propertyInfo.status && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Status:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.status }
                          </div>
                        </div>
                      )
                    }
                    {
                      propertyInfo.floors && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Floors:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.floors }
                          </div>
                        </div>
                      )
                    }
                    {
                      propertyInfo.developer && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Developer:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.developer }
                          </div>
                        </div>
                      )
                    }
                    {
                      propertyInfo.timeline && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Timeline:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.timeline }
                          </div>
                        </div>
                      )
                    }
                    {
                      propertyInfo.unitLayouts && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Unit Layouts:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.unitLayouts }
                          </div>
                        </div>
                      )
                    }
                    {
                      propertyInfo.keyDates && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Key Dates:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.keyDates }
                          </div>
                        </div>
                      )
                    }
                    {
                      propertyInfo.units && (
                        <div className="Prediction__contentBlockParametersItem Prediction__contentBlockParametersItem--column">
                          <div className="Prediction__contentBlockParametersItemKey">Units:</div>
                          <div className="Prediction__contentBlockParametersItemValue">
                            { propertyInfo.units }
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
            <div className="Prediction__contentBlock Prediction__contentBlock--scrollable">
              <h3 className="Prediction__contentBlockTitle">
                Infrastructure
              </h3>
              {
                !isContentAvailable ? (
                  <ContentPlug />
                ) : (
                  <div className="Prediction__contentBlockParameters">
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">&nbsp;</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--accent">
                        <i className="bi bi-geo-fill" />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--accent">
                        <i className="bi bi-car-front-fill" />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--accent">
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Closest Metro:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.closestMetro }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToMetro ? getFormattedNumber(propertyInfo.drivingDistanceToMetro, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.metroStationGmapsRating }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Closest Mall:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.closestMall }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToMall ? getFormattedNumber(propertyInfo.drivingDistanceToMall, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.mallGmapsRating }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Closest Landmark:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.closestLandmark }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToLandmark ? getFormattedNumber(propertyInfo.drivingDistanceToLandmark, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.landmarkGmapsRating }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Closest Beach:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.closestBeach }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToBeach ? getFormattedNumber(propertyInfo.drivingDistanceToBeach, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.beachGmapsRating }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Closest Hospital:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.closestHospital }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToHospital ? getFormattedNumber(propertyInfo.drivingDistanceToHospital, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : propertyInfo.hospitalGmapsRating }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </Col>
          <Col lg={6}>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Prediction Parameters
              </h3>
              <div className="Prediction__contentBlockParameters">
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Rooms Type:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ getEnumKeyByValue(PropertyRoomsTypes, propertyPredictionParameters.rooms) }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Registration Type:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ getEnumKeyByValue(PropertyRegistrationTypes, propertyPredictionParameters.registrationType) }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Transaction Method:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{getEnumKeyByValue(PropertyTransactionGroups, propertyPredictionParameters.transactionGroup) }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Area:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ propertyPredictionParameters.procedureArea }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Parking:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ propertyPredictionParameters.hasParking ? 'Yes' : 'No' }</div>
                </div>
              </div>
            </div>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Predicted Price
              </h3>
              <div className="Prediction__contentBlockParameters">
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Full Price:</div>
                  <div className="Prediction__contentBlockParametersItemValue">
                    {`~${propertyPredictedPriceFull ? getFormattedNumber(propertyPredictedPriceFull) : null}`}
                    <span className="Prediction__contentBlockParametersItemValueSpan"> AED</span>
                  </div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Price per m²:</div>
                  <div className="Prediction__contentBlockParametersItemValue">
                    {`~${propertyPredictedPricePerMeter ? getFormattedNumber(propertyPredictedPricePerMeter) : null}`}
                    <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Price Explanation
              </h3>
              <p>
                The price of real estate depends on many factors. Below are the factors that influence the formation of the price of an object:
              </p>
              {
                !isContentAvailable ? (
                  <ContentPlug />
                ) : (
                  <div className="Prediction__contentBlockParameters">
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Base price per m²:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Base price for real estate in this area
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { `~${getFormattedNumber(Number(propertyBasePrice))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Area:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how the area of the object affects the final price
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.procedureArea)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.procedureArea))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Rooms Type:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how the number of rooms in an object affects the final cost
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.rooms)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.rooms))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Parking:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how the presence of parking affects the final cost
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.hasParking)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.hasParking))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Building Complex:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows the impact of a residential complex on the final price of an object
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.masterProject)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.masterProject))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Nearest Beach, Mall, Metro:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how distance to beaches, malls and meters affects the final price of the property
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.nearestInfrastructure)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.nearestInfrastructure))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Nearest Landmarks:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how the distance to the nearest landmarks affects the final price of the property
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.landmarks)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.landmarks))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Historic Trend:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how the current market conditions affect the final price of the property
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.year)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.year))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Registration Type:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows the impact of the type of registration of a property on the final price of the property
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.regType)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.regType))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Transaction Method:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                Shows how the purchase method affects the final price of the property
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className={`Prediction__contentBlockParametersItemValue ${getFeaturesImpactColorClass(propertyPredictionFeaturesImpact.transGroup)}`}>
                        { `${getFormattedNumber(Number(propertyPredictionFeaturesImpact.transGroup))}` }
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">
                        Final price per m²:
                        <span className="Prediction__contentBlockParametersItemKeyInfo">
                          <OverlayTrigger
                            placement="right"
                            overlay={(
                              <Tooltip>
                                The final predicted price of the property, taking into account the influence of the factors above on the price
                              </Tooltip>
                            )}
                          >
                            <i className="bi bi-info-circle-fill" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        {`~${propertyPredictedPricePerMeter ? getFormattedNumber(propertyPredictedPricePerMeter) : null}`}
                        <span className="Prediction__contentBlockParametersItemValueSpan"> AED / m²</span>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
        <Row className="Prediction__content">
          <Col>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Location
              </h3>
              <p>
                { isPropertyInfoRequesting ? <Skeleton width={150} /> : `UAE, ${propertyInfo.masterProject}, ${propertyInfo.buildingName}, ${propertyInfo.projectName}` }
              </p>
              <div>
                {
                  !isPropertyInfoRequesting && (
                    <iframe title="Location on map" width="100%" height="600" src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${encodeURIComponent(`UAE ${propertyInfo.masterProject} ${propertyInfo.buildingName} ${propertyInfo.projectName}`)}+(Object)&t=&z=14&ie=UTF8&iwloc=B&output=embed`} />
                  )
                }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

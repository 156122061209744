import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { selectRefreshToken, setRefreshToken } from '../store/auth.reducer';
import { getCookie } from '../utils/cookies';

export const useGetRefreshToken = () => {
  const refreshTokenFromStore = useAppSelector(selectRefreshToken);
  const refreshTokenFromCookies = getCookie('refresh_token');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (refreshTokenFromStore) {
      return;
    }

    if (refreshTokenFromCookies) {
      dispatch(setRefreshToken(refreshTokenFromCookies));
    }
  }, [refreshTokenFromStore, refreshTokenFromCookies]);
};

import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import { getLimitsThunk } from '../store/limits.reducer';

export const useGetLimits = (accessToken: string | null) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    dispatch(getLimitsThunk({ accessToken }));
  }, [accessToken]);
};

import {
  RefreshAccessTokenRequest,
  RefreshAccessTokenResponse,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
} from './types';
import { ApiEndpoint, ApiEndpointVersion } from '../../constants';
import { makeRequest } from '../index';

export async function signIn({ email, password }: SignInRequest): Promise<SignInResponse | null> {
  return makeRequest<SignInResponse>({
    endpoint: ApiEndpoint.AUTH_SIGN_IN,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    data: { email, password },
  });
}

export async function signUp({ email, password }: SignUpRequest): Promise<SignUpResponse | null> {
  return makeRequest<SignUpResponse>({
    endpoint: ApiEndpoint.AUTH_SIGN_UP,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    data: { email, password },
  });
}

export async function refreshAccessToken({ refreshToken }: RefreshAccessTokenRequest): Promise<RefreshAccessTokenResponse | null> {
  return makeRequest<RefreshAccessTokenResponse>({
    endpoint: ApiEndpoint.AUTH_REFRESH_ACCESS_TOKEN,
    method: 'post',
    endpoint_version: ApiEndpointVersion.V1,
    data: { refreshToken },
  });
}

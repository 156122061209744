import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { getPropertyInfo } from '../api/property';
import { propertyAdjustmentsInitialState, PropertyAdjustmentsState } from './property.adjustments.reducer';

export interface PropertyInfo {
  id: number | null;
  masterProject: string;
  projectName: string;
  buildingName: string;
  closestMall: string;
  drivingDistanceToMall: number | null;
  mallGmapsRating: string;
  closestMetro: string;
  drivingDistanceToMetro: number | null;
  metroStationGmapsRating: string;
  closestLandmark: string;
  drivingDistanceToLandmark: number | null;
  landmarkGmapsRating: string;
  closestBeach: string;
  drivingDistanceToBeach: number | null;
  beachGmapsRating: string;
  closestHospital: string;
  drivingDistanceToHospital: number | null;
  hospitalGmapsRating: string;
  photos: string[];
  overview: string;
  status: string;
  floors: string;
  developer: string;
  timeline: string;
  unitLayouts: string;
  keyDates: string;
  units: string;
}

export interface PropertyInfoState {
  isRequesting: boolean;
  info: PropertyInfo;
  predictionParameters: PropertyAdjustmentsState;
}

export const propertyInfoInitialState: PropertyInfoState = {
  isRequesting: false,
  info: {
    id: null,
    masterProject: '',
    projectName: '',
    buildingName: '',
    closestMall: '',
    drivingDistanceToMall: null,
    mallGmapsRating: '',
    closestMetro: '',
    drivingDistanceToMetro: null,
    metroStationGmapsRating: '',
    closestLandmark: '',
    drivingDistanceToLandmark: null,
    landmarkGmapsRating: '',
    closestBeach: '',
    drivingDistanceToBeach: null,
    beachGmapsRating: '',
    closestHospital: '',
    drivingDistanceToHospital: null,
    hospitalGmapsRating: '',
    photos: [],
    overview: '',
    status: '',
    floors: '',
    developer: '',
    timeline: '',
    unitLayouts: '',
    keyDates: '',
    units: '',
  },
  predictionParameters: propertyAdjustmentsInitialState,
};

const propertyInfoSlice = createSlice({
  name: 'propertyInfo',
  initialState: propertyInfoInitialState,
  reducers: {
    setIsPropertyInfoRequesting(state, action: PayloadAction<boolean>) {
      state.isRequesting = action.payload;
    },
    setPropertyInfo(state, action: PayloadAction<PropertyInfo>) {
      state.info = action.payload;
    },
    setPropertyPredictionParameters(state, action: PayloadAction<PropertyAdjustmentsState>) {
      state.predictionParameters = action.payload;
    },
    resetPropertyInfo(state) {
      state.info = propertyInfoInitialState.info;
      state.predictionParameters = propertyInfoInitialState.predictionParameters;
    },
  },
  extraReducers: {},
});

const { reducer } = propertyInfoSlice;
export { reducer as propertyInfoReducer };

export const selectIsPropertyInfoRequesting = (state: AppState) => state.propertyInfo.isRequesting;
export const selectPropertyInfo = (state: AppState) => state.propertyInfo.info;
export const selectPropertyPredictionParameters = (state: AppState) => state.propertyInfo.predictionParameters;

export const {
  setIsPropertyInfoRequesting,
  setPropertyInfo,
  setPropertyPredictionParameters,
  resetPropertyInfo,
} = propertyInfoSlice.actions;

export const getPropertyInfoThunk = createAsyncThunk(
  'propertyInfo/getInfo',
  async ({
    id,
    accessToken,
  } : {
    id: number,
    accessToken: string,
  }, { dispatch }) => {
    dispatch(setIsPropertyInfoRequesting(true));

    const propertyInfoResponse = await getPropertyInfo({ id }, accessToken);

    if (!propertyInfoResponse) {
      return;
    }

    dispatch(setIsPropertyInfoRequesting(false));
    dispatch(setPropertyInfo(propertyInfoResponse));
  },
);

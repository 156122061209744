import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { SubFooter } from './components/SubFooter/SubFooter';
import { ProtectedRoute } from './hocs/ProtectedRoute/ProtectedRoute';
import { Landing } from './views/Landing/Landing';
import { Auth } from './views/AuthFlow/Auth/Auth';
import { Profile } from './views/Account/Profile/Profile';
import { Terms } from './views/Terms/Terms';
import { Packs } from './views/Payment/Packs/Packs';
import { SelectProject } from './views/PredictionFlow/SelectProject/SelectProject';
import { AdjustParameters } from './views/PredictionFlow/AdjustParameters/AdjustParameters';
import { Prediction } from './views/PredictionFlow/Prediction/Prediction';
import { useGetAccessToken } from './hooks/useGetAccessToken';
import { useGetRefreshToken } from './hooks/useGetRefreshToken';
import { useSetUserData } from './hooks/useSetUserId';
import { useGetLimits } from './hooks/useGetLimits';
import { Paths } from './constants';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { PaymentForm } from './views/Payment/PaymentForm/PaymentForm';
import { PaymentStatus } from './views/Payment/PaymentStatus/PaymentStatus';

export const App = () => {
  const accessToken = useGetAccessToken();
  const accessTokenAllowed = accessToken !== null;

  useGetRefreshToken();
  useSetUserData();
  useGetLimits(accessToken);

  return (
    <>
      <Header />
      <Routes>
        <Route path={Paths.MAIN} element={<Landing />} />
        <Route path={Paths.AUTH} element={accessTokenAllowed ? <Navigate to={Paths.PROPERTY_PREDICTION_SELECT_PROJECT} replace /> : <Auth />} />
        <Route path={Paths.TERMS} element={<Terms />} />
        <Route
          path={Paths.ACCOUNT_PROFILE}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <Profile />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PAYMENT_PACKS}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <Packs />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PAYMENT_FORM}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <PaymentForm />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PAYMENT_STATUS}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <PaymentStatus />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PROPERTY_PREDICTION_SELECT_PROJECT}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <SelectProject />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PROPERTY_PREDICTION_ADJUST_PARAMETERS}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <AdjustParameters />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PROPERTY_PREDICTION}
          element={(
            <ProtectedRoute isAllowed={accessTokenAllowed} redirectPath={Paths.MAIN}>
              <Prediction />
            </ProtectedRoute>
          )}
        />
        <Route
          path="*"
          element={<Navigate to={Paths.MAIN} replace />}
        />
      </Routes>
      <Footer />
      <SubFooter />
    </>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { createOrder, getOrders, getOrderStatus } from '../api/orders';
import { OrderItem } from '../api/orders/types';

export enum OrderStatuses {
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  CANCELLED = 'CANCELLED',
}

export interface OrdersState {
  processingOrder: {
    isRequesting: boolean;
    status: OrderStatuses;
    info: {
      id: number | null;
      confirmationToken: string;
    }
  };
  orders: {
    isRequesting: boolean;
    items: OrderItem[];
  };
}

export const ordersInitialState: OrdersState = {
  processingOrder: {
    isRequesting: true,
    status: OrderStatuses.PENDING,
    info: {
      id: null,
      confirmationToken: '',
    },
  },
  orders: {
    isRequesting: false,
    items: [],
  },
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState: ordersInitialState,
  reducers: {
    setIsProcessingOrderRequesting(state, action: PayloadAction<boolean>) {
      state.processingOrder.isRequesting = action.payload;
    },
    setProcessingOrderStatus(state, action: PayloadAction<OrderStatuses>) {
      state.processingOrder.status = action.payload;
    },
    setProcessingOrderInfo(state, action: PayloadAction<{ id: number, confirmationToken: string }>) {
      state.processingOrder.info = action.payload;
    },
    setIsOrdersRequesting(state, action: PayloadAction<boolean>) {
      state.orders.isRequesting = action.payload;
    },
    setOrders(state, action: PayloadAction<OrderItem[]>) {
      state.orders.items = action.payload;
    },
  },
  extraReducers: {},
});

const { reducer } = ordersSlice;
export { reducer as ordersReducer };

export const selectIsProcessingOrderRequesting = (state: AppState) => state.orders.processingOrder.isRequesting;
export const selectProcessingOrderStatus = (state: AppState) => state.orders.processingOrder.status;
export const selectProcessingOrderInfo = (state: AppState) => state.orders.processingOrder.info;
export const selectIsOrdersRequesting = (state: AppState) => state.orders.orders.isRequesting;
export const selectOrders = (state: AppState) => state.orders.orders.items;

export const {
  setIsProcessingOrderRequesting,
  setProcessingOrderStatus,
  setProcessingOrderInfo,
  setIsOrdersRequesting,
  setOrders,
} = ordersSlice.actions;

export const createOrderThunk = createAsyncThunk(
  'orders/createOrder',
  async ({
    accessToken,
    quantity,
    onSuccess,
  } : {
    accessToken: string,
    quantity: number
    onSuccess: () => void,
  }, { dispatch }) => {
    dispatch(setIsProcessingOrderRequesting(true));

    const newOrderInfoResponse = await createOrder(accessToken, quantity);

    if (!newOrderInfoResponse) {
      return;
    }

    dispatch(setProcessingOrderInfo({ id: newOrderInfoResponse.id, confirmationToken: newOrderInfoResponse.confirmationToken }));
    dispatch(setIsProcessingOrderRequesting(false));
    onSuccess();
  },
);

export const getPaymentStatusThunk = createAsyncThunk(
  'orders/getStatus',
  async ({
    accessToken,
    id,
    onSuccess,
  } : {
    accessToken: string,
    id: number
    onSuccess: () => void,
  }, { dispatch }) => {
    dispatch(setIsProcessingOrderRequesting(true));

    const newOrderStatusResponse = await getOrderStatus(accessToken, id);

    if (!newOrderStatusResponse) {
      return;
    }

    dispatch(setIsProcessingOrderRequesting(false));
    dispatch(setProcessingOrderStatus(newOrderStatusResponse.status));
    onSuccess();
  },
);

export const getOrdersThunk = createAsyncThunk(
  'orders/getOrders',
  async ({
    accessToken,
  } : {
    accessToken: string,
  }, { dispatch }) => {
    dispatch(setIsOrdersRequesting(true));

    const ordersResponse = await getOrders(accessToken);

    if (!ordersResponse) {
      return;
    }

    dispatch(setOrders(ordersResponse.orders));
    dispatch(setIsOrdersRequesting(false));
  },
);

import React, { FC, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import Benefits from './components/Benefits';
import TargetAudiences from './components/TargetAudiences';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import { Paths } from '../../constants';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../utils/amplitude';

import './Landing.css';

export const Landing: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.LANDING_VIEWED);
  }, []);

  const goToSignUp = useCallback((placement: string) => {
    sendAmplitudeEvent(AmplitudeEvents.LANDING_CTA_BUTTON_CLICKED, {
      placement,
    });
    navigate(Paths.AUTH);
  }, []);

  const onGetFreePackHandler = useCallback(() => {
    goToSignUp('Pricing (Free Pack)');
  }, []);

  const onGetPaidPackHandler = useCallback(() => {
    goToSignUp('Pricing (Paid Pack)');
  }, []);

  return (
    <Container fluid className="Landing">
      <Hero onGoToSignUp={goToSignUp} />
      <HowItWorks />
      <Benefits onGoToSignUp={goToSignUp} />
      <Pricing
        onGetFreePack={onGetFreePackHandler}
        onGetPaidPack={onGetPaidPackHandler}
      />
      <TargetAudiences onGoToSignUp={goToSignUp} />
      <FAQ />
    </Container>
  );
};

import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../../store';

import { FlowHeadline } from '../../../components/Flow/FlowHeadline/FlowHeadline';
import { FlowHeader } from '../../../components/Flow/FlowHeader/FlowHeader';
import { FlowText } from '../../../components/Flow/FlowText/FlowText';
import { Paths } from '../../../constants';
import { getPropertySearchResultsThunk, selectPropertySearchQuery, selectPropertySearchResults } from '../../../store/property.search.reducer';
import { selectAccessToken } from '../../../store/auth.reducer';
import { setIsPropertyAdjustmentsId } from '../../../store/property.adjustments.reducer';
import { selectAvailableFreePredictions, selectAvailablePaidPredictions, selectIsLimitsRequesting } from '../../../store/limits.reducer';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import { LimitBanner } from '../../../components/LimitBanner/LimitBanner';

import './SelectProject.css';

export const SelectProject: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accessToken = useAppSelector(selectAccessToken);
  const propertySearchQuery = useAppSelector(selectPropertySearchQuery);
  const propertySearchResults = useAppSelector(selectPropertySearchResults);
  const isLimitsRequesting = useAppSelector(selectIsLimitsRequesting);
  const availableFreePredictions = useAppSelector(selectAvailableFreePredictions);
  const availablePaidPredictions = useAppSelector(selectAvailablePaidPredictions);

  const [query, setQuery] = useState<string>(propertySearchQuery);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    if (query.length > 0) {
      dispatch(getPropertySearchResultsThunk({
        query,
        accessToken,
        onSuccess: () => {},
        onFailure: () => {},
      }));
    }
  }, [query, accessToken]);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.SELECT_PROJECT_VIEWED);
  }, []);

  const onResultClickHandler = useCallback((id: number) => {
    if (!id) {
      return;
    }

    dispatch(setIsPropertyAdjustmentsId(id));
    sendAmplitudeEvent(AmplitudeEvents.SELECT_PROJECT_SELECTED);
    navigate(Paths.PROPERTY_PREDICTION_ADJUST_PARAMETERS);
  }, []);

  const goToUpgradeLimits = useCallback(() => {
    navigate(Paths.PAYMENT_PACKS);
  }, []);

  return (
    <div className="SelectProject">
      <Container className="SelectProject__section Flow__section">
        <Row>
          <Col lg={3} />
          <Col lg={6}>
            <FlowHeadline
              text="Step 1/3"
              className="mb-3"
            />
            <FlowHeader
              text="🏠 Select the Residential Complex"
              className="mb-3"
            />
            <FlowText
              text="Choose a complex from the list to start:"
            />
            <div className="SelectProject__form">
              <InputGroup className="my-1">
                <Form.Control
                  placeholder="Start typing..."
                  value={query}
                  disabled={availableFreePredictions + availablePaidPredictions === 0}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <InputGroup.Text id="inputGroup-sizing-default">
                  🔎
                </InputGroup.Text>
              </InputGroup>
              <div className="my-1">
                {
                  propertySearchResults.map((item) => (
                    <div className="SelectProject__result" key={item.id} onClick={() => onResultClickHandler(item.id)}>
                      <div className="SelectProject__resultTitle">{item.projectName}</div>
                      {
                        item.buildingName ? (
                          <div className="SelectProject__resultSubtitle">{`${item.masterProject} • ${item.buildingName}`}</div>
                        ) : (
                          <div className="SelectProject__resultSubtitle">{ item.masterProject }</div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div>
              {
                !isLimitsRequesting && (
                  <LimitBanner
                    availablePaidPredictions={availablePaidPredictions}
                    availableFreePredictions={availableFreePredictions}
                    onGoToUpgradeLimits={goToUpgradeLimits}
                  />
                )
              }
            </div>
          </Col>
          <Col lg={3} />
        </Row>
      </Container>
    </div>
  );
};

export enum Paths {
  MAIN = '/',
  AUTH = '/auth',
  ACCOUNT_PROFILE = '/account/profile',
  PAYMENT_PACKS = '/payment/packs',
  PAYMENT_FORM = '/payment/form',
  PAYMENT_STATUS = '/payment/status',
  PROPERTY_PREDICTION = '/prediction',
  PROPERTY_PREDICTION_SELECT_PROJECT = '/prediction/select-project',
  PROPERTY_PREDICTION_ADJUST_PARAMETERS = '/prediction/adjust-parameters',
  PROPERTY_PREDICTION_RESULT = '/prediction/result',
  TERMS = '/terms',
}

export enum Langs {
  EN = 'en',
  RU = 'ru',
}

export const SUPPORTED_LANGUAGES = [Langs.EN, Langs.RU];

export enum ApiEndpoint {
  AUTH_SIGN_UP = 'user',
  AUTH_SIGN_IN = 'user/login',
  AUTH_REFRESH_ACCESS_TOKEN = 'user/refresh',
  PROPERTY_SEARCH = 'property/search',
  PROPERTY_PREDICT = 'property/predict',
  PROPERTY_GET_INFO = 'property/getById',
  USER_GET_INFO = 'user/get',
  LIMITS_GET = 'property/getPredictionLimits',
  PAYMENT_CREATE_ORDER = 'property/createUserPredictionOrder',
  PAYMENT_GET_STATUS = 'property/getUserPredictionOrder',
  PAYMENT_GET_ALL = 'property/getUserPredictionOrders',
}

export enum ApiEndpointVersion {
  V1 = 'v1',
}

export enum AuthFormTabs {
  AUTH_SIGN_IN_TAB = 'AUTH_SIGN_IN_TAB',
  AUTH_SIGN_UP_TAB = 'AUTH_SIGN_UP_TAB',
}

export enum PropertyTransactionGroups {
  'Sale' = 'SALE',
  'Mortgage' = 'MORTGAGE',
  'Gift' = 'GIFT',
}

export enum PropertyRegistrationTypes {
  'Existing Properties' = 'EXISTING',
  'Off-plan Properties' = 'OFF_PLAN',
}

export enum PropertyRoomsTypes {
  'Singe Room' = 'SINGLE_ROOM',
  'Studio' = 'STUDIO',
  'Penthouse' = 'PENTHOUSE',
  '1 B/R' = 'ONE_B_R',
  '2 B/R' = 'TWO_B_R',
  '3 B/R' = 'THREE_B_R',
  '4 B/R' = 'FOUR_B_R',
  '5 B/R' = 'FIVE_B_R',
  '6 B/R' = 'SIX_B_R',
}

export enum PredictionTypes {
  'FREE' = '1',
  'PAID' = '2',
}

import React, { FC, ReactNode, useMemo } from 'react';

import './FlowIcon.css';

export enum FlowIconThemes {
  NEUTRAL,
  SUCCESS,
  WARNING,
  DANGER,
}

interface FlowIconProps {
  icon: ReactNode;
  theme?: FlowIconThemes;
}

export const FlowIcon: FC<FlowIconProps> = ({
  icon,
  theme = FlowIconThemes.NEUTRAL,
}) => {
  const iconTheme = useMemo(() => {
    switch (theme) {
      case FlowIconThemes.NEUTRAL:
        return '';
      case FlowIconThemes.SUCCESS:
        return 'FlowIcon--success';
      case FlowIconThemes.WARNING:
        return 'FlowIcon--warning';
      case FlowIconThemes.DANGER:
        return 'FlowIcon--danger';
      default:
        return '';
    }
  }, [theme]);

  return (
    <div className={`FlowIcon ${iconTheme}`}>
      { icon }
    </div>
  );
};

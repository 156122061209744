import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Pricing from '../../Landing/components/Pricing';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import { Paths } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { createOrderThunk } from '../../../store/orders.reducer';
import { selectAccessToken } from '../../../store/auth.reducer';

import './Packs.css';

export const Packs: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(selectAccessToken);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.PAYMENT_PACKS_VIEWED);
  }, []);

  const onGetFreePackHandler = useCallback(() => {
    alert('We have credited you with 5 free predictions upon registration.');
  }, []);

  const onGetPaidPackHandler = useCallback((quantity: number) => {
    if (!accessToken) {
      return;
    }

    dispatch(createOrderThunk({
      accessToken,
      quantity,
      onSuccess: () => {
        navigate(Paths.PAYMENT_FORM);
        sendAmplitudeEvent(AmplitudeEvents.PAYMENT_ORDER_CREATED, {
          quantity,
        });
      },
    }));
  }, []);

  return (
    <Pricing
      isFreeCredited
      onGetFreePack={onGetFreePackHandler}
      onGetPaidPack={onGetPaidPackHandler}
    />
  );
};

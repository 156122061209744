const production = {
  API_URL: 'https://dubaiai.estate/api',
  AMPLITUDE: {
    API_KEY: 'ddbb558f3e0ff57bb45f4a333d87f0c7',
  },
  PAYMENT_RETURN_URL: 'https://dubaiai.estate/payment/status',
};

const development = {
  API_URL: 'https://dubaiai.estate/api',
  AMPLITUDE: {
    API_KEY: '73188654ff7d6f9c2134ad099c571d61',
  },
  PAYMENT_RETURN_URL: 'http://localhost:3000/payment/status',
};

const getConfig = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return development;
  }

  return production;
};

export const config = getConfig();

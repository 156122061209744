import axios, { Method, AxiosRequestConfig, AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { config } from '../config';
import { ApiEndpoint, ApiEndpointVersion } from '../constants';
import { refreshAccessToken } from './auth';
import { getCookie, setCookie } from '../utils/cookies';
import { store } from '../store';
import { setAccessToken, signOut } from '../store/auth.reducer';

const api = axios.create({
  baseURL: config.API_URL,
});

interface Arguments {
  endpoint: ApiEndpoint;
  method: Method,
  endpoint_version: ApiEndpointVersion;
  params?: Record<string, any>;
  data?: Record<string, any>;
  headers?: Record<string, any>;
}

export const makeRequest = async <T = never>({
  endpoint,
  method,
  endpoint_version,
  params,
  data,
  headers,
}: Arguments): Promise<T | any> => {
  const requestConfig: AxiosRequestConfig = {
    url: `${config.API_URL}/${endpoint_version}/${endpoint}`,
    method,
  };

  if (params) {
    requestConfig.params = params;
  }

  if (data) {
    requestConfig.data = data;
  }

  if (headers) {
    requestConfig.headers = headers;
  }

  try {
    const response = await api<T>(requestConfig);

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<any, any>;

    if (axiosError.response?.status === 403) {
      const refreshToken = getCookie('refresh_token');

      if (!refreshToken) {
        store.dispatch(signOut());
        throw axiosError;
      }

      try {
        const res = await refreshAccessToken({ refreshToken });
        const newAccessToken = res?.accessToken;

        if (!newAccessToken) {
          store.dispatch(signOut());
          throw axiosError;
        }

        store.dispatch(setAccessToken(newAccessToken));
        setCookie('access_token', newAccessToken);

        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization: `Bearer ${newAccessToken}`,
        };

        const retryResponse = await api<T>(requestConfig);
        return retryResponse.data;
      } catch (refreshError) {
        store.dispatch(signOut());
        throw refreshError;
      }
    }

    const errorMessage = axiosError.response?.data.message || axiosError.message || `${axiosError.response?.status} ${axiosError.response?.statusText} `;

    enqueueSnackbar(`An error occurred: ${errorMessage}`, { variant: 'error' });
    throw error;
  }
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { AmplitudeEvents, initAmplitude, sendAmplitudeEvent } from './utils/amplitude';

import { store } from './store';

import './index.css';

import { App } from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
);

initAmplitude();
sendAmplitudeEvent(AmplitudeEvents.SESSION_STARTED);

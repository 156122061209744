import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { getPropertyPricePrediction } from '../api/predict';
import {
  PropertyPricePredictionRequest,
  PropertyPriceFreePredictionResponse,
  PropertyPricePaidPredictionResponse,
  PropertyPricePredictionFeaturesImpactData,
} from '../api/predict/types';
import { resetPropertyInfo, setPropertyPredictionParameters } from './property.info.reducer';
import { getLimitsThunk } from './limits.reducer';
import { PredictionTypes } from '../constants';
import { resetPropertySearch } from './property.search.reducer';

export interface PropertyPredictionState {
  isRequesting: boolean;
  type: PredictionTypes;
  predictedPrice: number | null;
  basePrice: number | null;
  featuresImpact: PropertyPricePredictionFeaturesImpactData;
}

export const propertyPredictionInitialState: PropertyPredictionState = {
  isRequesting: false,
  type: PredictionTypes.FREE,
  predictedPrice: null,
  basePrice: null,
  featuresImpact: {
    masterProject: null,
    rooms: null,
    transGroup: null,
    year: null,
    nearestInfrastructure: null,
    landmarks: null,
    hasParking: null,
    regType: null,
    procedureArea: null,
  },
};

const propertyPredictionSlice = createSlice({
  name: 'propertyPrediction',
  initialState: propertyPredictionInitialState,
  reducers: {
    setIsPropertyPredictionRequesting(state, action: PayloadAction<boolean>) {
      state.isRequesting = action.payload;
    },
    setPropertyPredictionType(state, action: PayloadAction<PredictionTypes>) {
      state.type = action.payload;
    },
    setPropertyPredictedPrice(state, action: PayloadAction<number>) {
      state.predictedPrice = action.payload;
    },
    setPropertyBasePrice(state, action: PayloadAction<number | null>) {
      state.basePrice = action.payload;
    },
    setPropertyPredictionFeaturesImpact(state, action: PayloadAction<PropertyPricePredictionFeaturesImpactData>) {
      state.featuresImpact = action.payload;
    },
    resetPredictionInfo(state) {
      state.predictedPrice = propertyPredictionInitialState.predictedPrice;
      state.basePrice = propertyPredictionInitialState.basePrice;
      state.featuresImpact = propertyPredictionInitialState.featuresImpact;
    },
  },
  extraReducers: {},
});

const { reducer } = propertyPredictionSlice;
export { reducer as propertyPredictionReducer };

export const selectIsPropertyPredictionRequesting = (state: AppState) => state.propertyPrediction.isRequesting;
export const selectPropertyPredictionType = (state: AppState) => state.propertyPrediction.type;
export const selectPropertyPredictedPrice = (state: AppState) => state.propertyPrediction.predictedPrice;
export const selectPropertyBasePrice = (state: AppState) => state.propertyPrediction.basePrice;
export const selectPropertyPredictionFeaturesImpact = (state: AppState) => state.propertyPrediction.featuresImpact;

export const {
  setIsPropertyPredictionRequesting,
  setPropertyPredictionType,
  setPropertyPredictedPrice,
  setPropertyBasePrice,
  setPropertyPredictionFeaturesImpact,
  resetPredictionInfo,
} = propertyPredictionSlice.actions;

export const getPropertyPricePredictionThunk = createAsyncThunk(
  'propertyPrediction/getPrice',
  async ({
    adjustments,
    accessToken,
    onSuccess,
    onFailure,
  } : {
    adjustments: PropertyPricePredictionRequest,
    accessToken: string,
    onSuccess: (price: number, predictionType: PredictionTypes) => void,
    onFailure: () => void,
  }, { dispatch }) => {
    dispatch(setIsPropertyPredictionRequesting(true));
    dispatch(resetPredictionInfo());
    dispatch(resetPropertyInfo());

    const propertyPricePredictionResponse = await getPropertyPricePrediction(adjustments, accessToken);

    if (!propertyPricePredictionResponse) {
      dispatch(setIsPropertyPredictionRequesting(false));
      onFailure();
      return;
    }

    if ((propertyPricePredictionResponse as PropertyPriceFreePredictionResponse).limitedPrediction) {
      const prediction = (propertyPricePredictionResponse as PropertyPriceFreePredictionResponse).limitedPrediction;

      dispatch(setPropertyPredictedPrice(prediction.predictedPrice));
      dispatch(setPropertyBasePrice(prediction.basePrice)); // временно, для запуска
      dispatch(setPropertyPredictionFeaturesImpact(prediction.featuresImpact)); // временно, для запуска
      dispatch(setPropertyPredictionType(PredictionTypes.FREE));
      onSuccess(prediction.predictedPrice, PredictionTypes.FREE);
    } else {
      const prediction = (propertyPricePredictionResponse as PropertyPricePaidPredictionResponse).fullPrediction;

      dispatch(setPropertyPredictedPrice(prediction.predictedPrice));
      dispatch(setPropertyBasePrice(prediction.basePrice));
      dispatch(setPropertyPredictionFeaturesImpact(prediction.featuresImpact));
      dispatch(setPropertyPredictionType(PredictionTypes.PAID));
      onSuccess(prediction.predictedPrice, PredictionTypes.PAID);
    }

    dispatch(setIsPropertyPredictionRequesting(false));
    dispatch(setPropertyPredictionParameters(adjustments));
    dispatch(getLimitsThunk({ accessToken }));
    dispatch(resetPropertySearch());
  },
);

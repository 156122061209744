import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { selectAccessToken, setAccessToken } from '../store/auth.reducer';
import { getCookie } from '../utils/cookies';

export const useGetAccessToken = () => {
  const dispatch = useAppDispatch();
  const accessTokenFromStore = useAppSelector(selectAccessToken);
  const accessTokenFromCookies = getCookie('access_token');

  return useMemo(() => {
    if (accessTokenFromStore) {
      return accessTokenFromStore;
    }

    if (accessTokenFromCookies) {
      dispatch(setAccessToken(accessTokenFromCookies));
      return accessTokenFromCookies;
    }

    return null;
  }, [accessTokenFromStore, accessTokenFromCookies]);
};

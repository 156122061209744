import React from 'react';
import {
  Container,
  Row,
  Col,
  Accordion,
} from 'react-bootstrap';

const FAQ: React.FC = () => {
  return (
    <section className="FAQ" id="FAQ">
      <Container className="FAQ__section">
        <Row>
          <Col>
            <div className="FAQ__content">
              <h2 className="FAQ__contentTitle">Frequently Asked Questions</h2>
              <p className="FAQ__contentText">
                Find the information you need to get the most out of our real estate price prediction tool
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How accurate are the price predictions?</Accordion.Header>
                <Accordion.Body>
                  Our predictions are highly accurate, thanks to our model being trained on millions of real estate transactions in Dubai. While no prediction can be 100% accurate due to market fluctuations, our tool consistently provides a reliable estimate based on current and historical data.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>What data does the model use?</Accordion.Header>
                <Accordion.Body>
                  Our model utilizes a comprehensive dataset that includes millions of real estate transactions in Dubai. This data encompasses various factors such as property size, location, amenities, historical sales prices, market trends, and more, allowing the model to provide a well-rounded and informed price prediction.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Can I use this tool for commercial properties?</Accordion.Header>
                <Accordion.Body>
                  Currently, our tool is optimized for residential properties in Dubai. We are working on expanding our capabilities to include commercial properties in the near future. Stay tuned for updates!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How does the tool explain the price prediction</Accordion.Header>
                <Accordion.Body>
                  The tool provides a detailed breakdown of the factors that influence the predicted price. This includes aspects like the property&apos;s location, number of rooms, type of purchase, amenities, market trends, and more. Each factor&apos;s impact on the final price is clearly explained, helping you understand the reasoning behind the valuation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>How many free predictions do I get?</Accordion.Header>
                <Accordion.Body>
                  You have a 5 free predictions after sign up. This allows you to test the tool and see its value before deciding to upgrade limits.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>How can I contact support if I have an issue?</Accordion.Header>
                <Accordion.Body>
                  If you encounter any issues or have questions, our dedicated support team is here to help. You can reach us via email at quvedevelopment@ya.ru. We aim to respond to all inquiries within 24 hours.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FAQ;

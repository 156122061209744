import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Paths } from '../../constants';

import './ContentPlug.css';

export const ContentPlug: FC = () => {
  return (
    <div className="ContnentPlug">
      <div className="ContnentPlug__text">
        <i className="bi bi-lock-fill pe-2" />
        <span>
          Content is locked.
        </span>
        <br />
        <span>
          Use&nbsp;
          <NavLink to={Paths.PAYMENT_PACKS}>paid predictions</NavLink>
          &nbsp;to get a full description of the property.
        </span>
      </div>
      <div className="ContnentPlug__hidden">
        <div className="ContnentPlug__hiddenText">
          <p>
            Nice try :) But we should say here: &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.&quot;
          </p>
        </div>
        <div className="ContnentPlug__hiddenBlock" />
      </div>
    </div>
  );
};

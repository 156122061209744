import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { selectAccessToken } from '../store/auth.reducer';
import { setUserEmail, setUserId } from '../store/user.reducer';
import { parseJWT } from '../utils/common';

export const useSetUserData = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const { id, email } = parseJWT(accessToken);

    dispatch(setUserId(id));
    dispatch(setUserEmail(email));
  }, [accessToken]);
};

import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Navbar,
  Nav,
} from 'react-bootstrap';

import logo from '../../assets/logo.svg';
import { Paths } from '../../constants';

import './Footer.css';

export const Footer: FC = () => {
  return (
    <section className="Footer py-0" id="Footer">
      <Navbar expand="lg" collapseOnSelect>
        <Container className="py-4">
          <Row>
            <Col lg={4} className="mb-4 mb-lg-0">
              <img src={logo} alt="Logo" height="18" className="mb-3" />
              <p className="mb-0 pe-0">
                AI-powered tool provides precise property price predictions for Dubai real estate, offering transparency and detailed insights for buyers, sellers, agents, investors, and developers. Empower your real estate decisions with data-driven accuracy.
              </p>
            </Col>
            <Col lg={2} />
            <Col lg={3} className="mb-4 mb-lg-0">
              <h5>Menu</h5>
              <Nav className="d-block">
                <NavLink to={Paths.MAIN} className="Footer__navLink nav-link">Main</NavLink>
                <Nav.Link href={`${Paths.MAIN}#Pricing`} className="Footer__navLink nav-link">Pricing</Nav.Link>
                <Nav.Link href={`${Paths.MAIN}#FAQ`} className="Footer__navLink nav-link">FAQ</Nav.Link>
              </Nav>
            </Col>
            <Col lg={3}>
              <h5>Contacts</h5>
              <Nav className="d-block">
                <Nav.Link href="mailto:quvedevelopment@ya.ru" className="Footer__navLink nav-link">quvedevelopment@ya.ru</Nav.Link>
                <Nav.Link href="tel:+79771628078" className="Footer__navLink nav-link">+7 (977) 162-80-78</Nav.Link>
              </Nav>
              <h5>Legal</h5>
              <Nav className="d-block">
                <NavLink to={Paths.TERMS} className="Footer__navLink nav-link">Terms & Conditions</NavLink>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </section>
  );
};

import React, { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import { FlowHeader } from '../../../components/Flow/FlowHeader/FlowHeader';
import { Skeleton } from '../../../components/Skeleton/Skeleton';
import { Paths } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectUserEmail,
  selectUserId,
} from '../../../store/user.reducer';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import {
  selectAvailableFreePredictions,
  selectAvailablePaidPredictions,
  selectIsLimitsRequesting,
} from '../../../store/limits.reducer';
import { selectAccessToken } from '../../../store/auth.reducer';
import { getOrdersThunk, selectOrders } from '../../../store/orders.reducer';

import './Profile.css';

export const Profile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const id = useAppSelector(selectUserId);
  const email = useAppSelector(selectUserEmail);
  const isLimitsRequesting = useAppSelector(selectIsLimitsRequesting);
  const availableFreePredictions = useAppSelector(selectAvailableFreePredictions);
  const availablePaidPredictions = useAppSelector(selectAvailablePaidPredictions);
  const accessToken = useAppSelector(selectAccessToken);
  const orders = useAppSelector(selectOrders);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.SELECT_PROJECT_VIEWED);
  }, []);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    dispatch(getOrdersThunk({ accessToken }));
  }, [accessToken]);

  return (
    <div className="Profile">
      <Container className="Profile__section">
        <Row>
          <Col>
            <FlowHeader
              text="💎 Account"
              className="mb-3"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div className="Profile__info">
              <h4 className="Profile__infoBlockTitle">
                Details
              </h4>
              <div className="Profile__infoBlockParameters">
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">Email:</div>
                  <div className="Profile__infoBlockParametersItemValue">{ email }</div>
                </div>
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">ID:</div>
                  <div className="Profile__infoBlockParametersItemValue">{ id }</div>
                </div>
              </div>
            </div>
            <div className="Profile__info">
              <h4 className="Profile__infoBlockTitle">
                Limits
              </h4>
              <div className="Profile__infoBlockParameters mb-3">
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">Free predictions:</div>
                  <div className="Profile__infoBlockParametersItemValue">
                    { isLimitsRequesting ? <Skeleton /> : availableFreePredictions }
                  </div>
                </div>
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">Paid predictions:</div>
                  <div className="Profile__infoBlockParametersItemValue">
                    { isLimitsRequesting ? <Skeleton /> : availablePaidPredictions }
                  </div>
                </div>
              </div>
              <Button onClick={() => navigate(Paths.PAYMENT_PACKS)}>Upgrade Limits</Button>
            </div>
          </Col>
          <Col lg={8}>
            <div className="Profile__info">
              <h4 className="Profile__infoBlockTitle">
                Payments
              </h4>
              <div className="Profile__infoBlockTable mb-3">
                {
                  orders.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Date</th>
                          <th>Predictions</th>
                          <th>Price</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orders.map((order) => (
                            <tr key={order.id}>
                              <td>{ order.id }</td>
                              <td>{ order.createdAt }</td>
                              <td>{ order.quantity }</td>
                              <td>{ `${order.amount.value} ${order.amount.currency}` }</td>
                              <td>
                                <Link to={`${Paths.PAYMENT_STATUS}?id=${order.id}`}>
                                  { order.status }
                                </Link>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  ) : (
                    <p>You don&apos;t have any payments yet.</p>
                  )
                }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
